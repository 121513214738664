import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

// Interface
import { FunctionalitiesProps } from 'components/flex/Functionalities/FunctionalitiesShell'

SwiperCore.use([Pagination])

const IconWrapper = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.color.dark};
  border-radius: 30px;

  @media (max-width: 991px) {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 116px;
    height: 116px;
  }

  @media (max-width: 991px) {
    width: 69px;
    height: 69px;
  }
`

const BackgroundTitle = styled.h3`
  bottom: 0;
  right: 25px;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  opacity: 0.15;
  color: ${({ theme }) => theme.color.grey};

  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 100px;
  }

  @media (max-width: 991.98px) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (max-width: 575.98px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const Functionality = styled.div`
  background-color: ${({ theme }) => theme.color.grey};

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  @media (min-width: 992px) {
    padding: 2.2rem 3rem 2.2rem 3rem;
    margin-left: -0.5rem;
    & h3 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 991px) {
    & h3 {
      font-size: ${({ theme }) => theme.font.size.bigger};
    }
  }
`

const Content = styled(ParseContent)`
  z-index: 2;

  & ul {
    margin-bottom: 0;
  }
`

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;

    & .swiper-slide {
      height: auto;
    }
  }
`

const Functionalities: React.FC<FunctionalitiesProps> = ({ fields }) => {
  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <section className="mb-lg-5 pb-5">
      <div className="container" ref={containerRef}>
        <div className="row justify-content-center d-lg-flex d-none">
          <div className="col-xl-10">
            <ParseContent content={fields.description} className="mb-5" />
            {fields.functionalities?.map((functionality, index) => (
              <div className="d-flex flex-column flex-sm-row mb-4" key={index}>
                <IconWrapper className="position-relative d-flex justify-content-center p-sm-5">
                  <Icon image={functionality?.icon} alt="" />
                </IconWrapper>
                <div className="position-relative w-100 py-lg-2">
                  <BackgroundTitle className="position-absolute">
                    {functionality?.backgroundtitle}
                  </BackgroundTitle>
                  <Functionality className="w-100 h-100 px-5 py-4">
                    <div className="d-flex flex-column flex-lg-row justify-content-between ps-lg-4 py-lg-2">
                      <h3 className="text-center text-sm-start">
                        {functionality?.title}
                      </h3>
                      <ButtonSecondary
                        to={functionality?.link?.url || '#'}
                        className="mb-4 mb-lg-0"
                      >
                        {functionality?.link?.title}
                      </ButtonSecondary>
                    </div>
                    <Content
                      content={functionality?.description}
                      className="position-relative"
                    />
                  </Functionality>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {marginLeft !== null && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <ParseContent content={fields.description} className="mb-5" />

          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.25,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
            className="h-100"
          >
            {fields.functionalities?.map((functionality, index) => (
              <SwiperSlide>
                <div className="d-flex flex-column h-100" key={index}>
                  <IconWrapper className="position-relative d-flex justify-content-center py-3 p-sm-5">
                    <Icon image={functionality?.icon} alt="" />
                  </IconWrapper>
                  <div className="position-relative w-100 h-100 py-lg-2">
                    <BackgroundTitle className="position-absolute">
                      {functionality?.backgroundtitle}
                    </BackgroundTitle>
                    <Functionality className="w-100 h-100 px-2 py-4">
                      <div className="d-flex flex-column flex-lg-row justify-content-between">
                        <h3 className="text-center text-lg-start">
                          {functionality?.title}
                        </h3>
                      </div>
                      <Content
                        content={functionality?.description}
                        className="position-relative mb-4"
                      />
                      <div className="text-center">
                        <ButtonSecondary
                          to={functionality?.link?.url || '#'}
                          className="mb-4 mb-lg-0"
                        >
                          {functionality?.link?.title}
                        </ButtonSecondary>
                      </div>
                    </Functionality>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperContainer>
      )}
    </section>
  )
}

export default Functionalities
